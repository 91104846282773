/*
 * @Auth: linjituan
 * @Date: 2022-03-24 19:27:02
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-25 17:32:51
 */
import request from '@/utils/request'
const baseURL = '/rc-api'

// 查询登录日志列表
export function list(query) {
  return request({
    url: '/internal/recyclable/logininfor/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 删除登录日志
export function delLogininfor(infoId) {
  return request({
    url: '/internal/recyclable/logininfor/' + infoId,
    baseURL,
    method: 'delete'
  })
}

// 清空登录日志
export function cleanLogininfor() {
  return request({
    url: '/internal/recyclable/logininfor/clean',
    baseURL,
    method: 'delete'
  })
}

// 导出登录日志
export function exportLogininfor(query) {
  return request({
    url: '/internal/recyclable/logininfor/export',
    baseURL,
    method: 'get',
    params: query
  })
}

// 最近登录的用户
export function listLastLoginName(query) {
  return request({
    url: '/internal/recyclable/logininfor/listLastLoginName',
    baseURL,
    method: 'get',
    params: query
  })
}
